.footer {
  width: 100%;
  padding: 2rem 0 3rem 0;
  /* background-color: rgba(19, 19, 19, 0.8); */
  background-color: #373737;
}

h1,
h4,
p,
a {
  color: #fff;
  text-decoration: none;
}

.footer-div {
  color: #fff;
}

.footer-end {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  margin: auto;
  width: 50%;
  text-align: center;
}


.footer-container .p {
  color: #fff;
  text-decoration: none;
}

.footer-container .h1 {
  color: #fff;
  text-decoration: none;
}

.footer-container {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
}

.left {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: left;
}

.center {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: center;
  color: white;
}

.location {
  display: flex;
  margin-bottom: 0.8rem;
}

.location p {
  line-height: 30px;
}

.right {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: center;
  color: white;
}

.right h4{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.social {
  margin-top: 1rem;
}

h4 {
  font-size: 2rem;
  padding-bottom: 0.7rem;
}

h5 {
  color: #fff;
  font-size: 20px;
}
h3 {
  color: #fff;
  font-size: 3rem;
}

p {
  font-size: 1.2rem;
  color: #fff;
}

@media screen and (max-width: 640px) {
  .footer-container {
    grid-template-columns: 1fr;
    height: auto;
    width: 100%;
  }


  .left{
    text-align: center;
  }
}

.rest h4 {
  color: white;
}
