.container1 {
    padding: 0;
    background-color: #f3edfb;
    padding-right: 1px;
    padding-bottom: 0px;
  }
  .row1 {
    display: table;
    padding-bottom: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #f3edfb;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .row2 {
    display: table;
    padding-bottom: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #f3edfb;
  }

  
.fontlink {
  font-family: "Spectral", serif;
}
  
  
  
  .column-66 {
    float: left;
    width: 40%;
    height: 600px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-top: 30px;

    text-align: center;
    vertical-align: middle;
    overflow: scroll;
    background-color: #f3edfb;
  }

  .column-66 p {
    color: rgb(59, 5, 86);
    font-size: 27px;
  }


  .column-88 {
    float: right;
    width: 40%;
    height: 600px;
    padding-left: 5px;
    padding-right: 50px;
    padding-bottom: 0px;
    padding-top: 30px;

    text-align: center;
    vertical-align: middle;
    background-color: #f3edfb;
  }

  .column-88 p {
    color: rgb(59, 5, 86);
    font-size: 27px;
  }
  
  .column-33 {
    background-color: #f3edfb;
    float: right;
    width: 60%;
    height: 600px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 2px;
    object-fit: contain;
    overflow: hidden;
    text-align: right;
    position: relative;
    right: 0px;
    margin-right: 0;
    display: flex;
  }
  
  .column-33 img {
    display: flex;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    position: relative;
    right: 0px;
    margin-right: 0;
    margin-left: auto;
  }


  .column-99 {
    background-color: #f3edfb;
    float: left;
    width: 60%;
    height: 600px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    object-fit: contain;
    overflow: hidden;
    text-align: left;
    position: relative;
    right: 0px;
    margin-right: 0;
    display: flex;
  }
  
  .column-99 img {
    display: flex;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    right: 0px;
    width: 100%;

  margin-right: 0;
  margin-left: auto;
  }
  
  
  
  .large-font {
    color: rgb(255, 255, 255);
    font-size: 48px;
  }
  
  .xlarge-font {
    color: black;
    font-size: 64px;
  }
  
  
  
  @media screen and (max-width: 900px) {

    .row1{
      display: flex;
      flex-direction: column-reverse;
    }
    .row2{
      display: flex;
      flex-direction: column;
    }
    .column-66,
    .column-33 {
      width: 100%;
      text-align: center;
      height: auto;

    }
    img {
      margin: auto;
    }

    .column-99,
  .column-88 {
    width: 100%;
    text-align: center;
    height: auto;
  }
  img {
    margin: auto;
  }
  }
  