.emailForm {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: #241b35;

    border-radius: 25px;
    width:700px;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    margin: auto;
    max-width: 700px;
    border-radius: 25px;
    border: 3px solid #609;
    padding-top: 20px;
    padding-bottom: 20px;

  }

  form h1{
text-align: center;
color: white;
  }


  input{
    background-color:#fff;
    color: black;
    padding: 10px;
    margin: 5px;
    border-radius: 25px;
    border: 3px solid #609;
    text-align: center;
    font-size: 20px;



  }

  form textarea {
    border-radius: 25px;
    border: 2px solid #609;
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    background-color:#fff;
    color:black;
  }

  button[type=submit]{
    background-color: #84b8bf;
  border: none;
  color: white;
  padding: 20px;
  border-radius: 25px;
  border-color: #241b35;
  border-width: 5px;;
  font-size: 20px;
  font-weight: 900;
  text-align: center;




  }


  @media screen and (max-width: 900px) {

    .emailForm{
      width: 100%;
    }
  }
