.work-container {
  background-color: #241b35;
}

.imagencard img{
  height: 100%;
  width: 100%;
 
  

}

.caja img{
  object-fit: cover;
  

}

.imagewrap{
  height: 220px;
  display: flex;
  overflow: hidden;
}



.project-heading {
    text-align: center;
    padding: 1rem 0 2rem 0;
  }
  
  .project-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
  }
  
  .project-container text {
    color: #fff;
  }
  
  .project-card {
    background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 5px 25px 5px 50px;
  border: 5px solid #6c35de;
  overflow: hidden;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  margin: 0;
  padding: 0;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  }

  .project-card:focus,
  .project-card:hover {
    transform: scale(1.06);
  box-shadow: 0 10px 5px -5px rgba(0, 0, 0, 0.2);
  }



  
  .project-card img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .project-card text {
    text-align: justify;
    color: #a11b1b;
  }

  .project-titulo {
    height: 80px;
    padding-bottom: 10px;
    justify-content: center;
  }
  
  .project-title {
    color: #000000;
    padding: 1rem;
    text-align: justify;
    justify-content: center;
  }

  .pro-details {
    overflow-y: auto; 
    max-height: 300px;
    min-height: 300px;
  }
  
  
  .pro-details p {
    overflow-y: scroll;
  padding-bottom: 0.2rem;
  font-size: 1rem;
  padding: 1em;
  padding-bottom: 10px;
  text-align: justify;
  }

  
  
  .pro-btns {
    display: flex;
  justify-content: center;
  padding: 1rem 1rem;
  background-color: #6c35de;
  left: 50%;
  top: 50%;
  }
  
  .pro-btns .btn {
    padding: 0.5rem 1rem;
    justify-content: center;
  }

  .pro-btns .center {
    padding: 1rem;
    margin: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  
  @media screen and (max-width: 740px) {
    .project-container {
      max-width: 90%;
      margin: auto;
      grid-template-columns: 1fr;
    }
  }
  
  
  /* start from here  */
  
  