
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;1,500&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Playfair Display", serif;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #02182E;*/
  background: #f3edfb;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
}

h1,
h4,
p,
a {
  color: #fff;
  text-decoration: none;
}

h1 {
  color: #fff;
  font-size: 39px;
}

.btn {
  /*
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: #80a1c1;
  color: #ffd5ff;
  border: 1px solid #fff;
  font-weight: 400;
  cursor: pinter;
  */
  display: inline-block;

  font: normal normal 300 1.3em 'Open Sans';
  color: #fff;
  brackground-color: transparent;
  border: 3px solid rgb(76, 26, 97);


  border-radius: 100px;
  background-size: 200% 100%;
  background-image: linear-gradient(to right, transparent 50%, #cb80ff 50%);
  transition: background-position .3s cubic-bezier(0.19, 1, 0.22, 1) .1s, color .5s ease 0s, background-color .5s ease;


}

.btn-light {
  background: transparent;
  color: #000000;
  padding: 10px;
}

.btn:hover {
  color: rgb(105, 20, 99);
  background-color: rgb(232, 191, 244);
  background-position: -100% 100%;
  /*transition: 0.3s;*/
}





