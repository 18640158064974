.hero-img {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    height: 60vh;
    position: relative;
  }
  
  .hero-img::before {
    content: "";
   /* background: url("https://img.freepik.com/free-photo/interior-shot-modern-house-kitchen-with-large-windows_181624-24368.jpg?w=2000&t=st=1698696114~exp=1698696714~hmac=0a5461c3a014001bceca3199cbad28834b3a44b114d470575c210071783716d4"); */
   background: url("../assets/HomeCleaning.jpeg");
    background-size:cover;
    background-position: center ;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .hero-img h1 {
    font-size: 3.8rem;
  }
  
  .hero-img p {
    font-size: 2rem;
    text-align: center;
    color: white
  }
  
  @media screen and (max-width: 640px) {
    .hero-img h1 {
      font-size: 2rem;
    }
  }
  