
.about {
  width: 100%;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  background-color: #f3edfb;
}

.about .leftt {
  text-align: center;
  margin: auto;
  padding: 1.5rem;
  width: 40%;
  height: 65%;
  color: black;
  background-color: #2a1930;
  display: table;
  border-radius: 25px;

  

}

.about .leftt p {
  margin: 1.2rem 0;
  color: rgb(216, 194, 227);
  font-size: 21px;
  font-weight: bold;
}

.about .leftt h1 {
  color: rgb(158, 30, 147);
}

.about .rightt {
  display: table;
  width: 50%;
  background-color: #aa7acc;
}

.rightt .img-container {
  display: flex;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
  align-items: center;
  text-align: center;
  width: 100%;
  background-color: #9c6abf;

}

.rightt .img {
  max-width: 100%;
  border: 1px solid #333;
  width: 100%;
  background-color: #cb80ff;
  height: 600px;

}



.bttn {
  display: inline-block;
  font: normal normal 500 2.3em "Open Sans";
  font-size: 35px;
  padding: 10px;
  color: #000000;
  brackground-color: #f3edfb;
  border: 4px solid rgb(113, 20, 127);
  border-radius: 100px;
  background-size: 200% 100%;
  background-image: linear-gradient(to right, transparent 50%, #cb80ff 50%);
  transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s,
    color 0.5s ease 0s, background-color 0.5s ease;
}


@media screen and (max-width: 900px) {


 
  
  .about{
    width: 100%;
    flex-direction: column-reverse;
  }
   .leftt,.rightt,.about .leftt , .about .right{

    
    width: 100%;
    text-align: center;
  }
  .about .rightt img {
    height: 250px;
    width: 100%;
    object-fit:cover ;
    
  }

 
}